import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/header'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          siteOptions {
            acf {
              landing {
                title
                video
                mobileVideo
              }
            }
          }
        }
      }
    `}
    render={data => {

      return (
        <>
          <Header />
          <main>{ children }</main>
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
