import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  

  constructor(props) {
    super(props)
    this.form = React.createRef();
    
    this.state = {
      result: '',
      msg: ''
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.form.current.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.form.current.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  render() {
    let props = {
      ref: this.form,
      name: "contact",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    let { date } = this.state

    if (this.state.result === "success")
      return (
        <p className="contact__success">
          Thanks for getting in contact with us.
          <br /> A member of our team will be in touch soon.
        </p>
      )

    return (
      <form {...props}>
        <div className="form__row form__row--double">
          <input
            type="text"
            name="first_name"
            placeholder="First Name*"
            onChange={this.handleChange}
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name*"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <input
            type="email"
            name="email"
            placeholder="Your email*"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row form__row--double">
          <input
            type="text"
            name="phone"
            placeholder="Contact Number"
            onChange={this.handleChange}
          />
          <input
            type="text"
            name="business_name"
            placeholder="Business Name"
            onChange={this.handleChange}
          />
        </div>
        <div className="form__row">
          <textarea
            name="message"
            placeholder="What can you tell us about your project?"
            onChange={this.handleChange}
          />
        </div>
        <div className="form__row form__row--submit">
          <button type="submit" className="btn">
            Submit enquiry
          </button>
        </div>
      </form>
    )
  }
}

export default Form
