import React, { Component } from 'react'
import Link from '../utils/link'
import Form from './contact-form'

import Logo from '../assets/images/logo.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    contact: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleContact = () => {
    this.setState({ contact: !this.state.contact })
  }

  render() {

    let { offCanvas, contact } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <button onClick={this._toggleContact} className='header__contact'>
              Contact
            </button>
            <Link to='/' title='Site Name' className='header__logo' {...props}>
              <img src={Logo} alt={`Xmedia`} />
            </Link>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/' {...props}>Films</Link></li>
                <li><Link to='/photos/' {...props}>Photos</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li>
                  <button onClick={this._toggleContact}>
                    Contact
                  </button>
                </li>
              </ul>
            </nav>
            <div className='off-canvas__social'>
              <ul>
                <li>Let's Talk</li>
                <li><Link to='mailto:bookings@x-media.com.au'>bookings@x-media.com.au</Link></li>
                <li><Link to='tel:+61415311721'>+61 415 311 721</Link></li>
              </ul>
              <ul>
                <li>Connect</li>
                <li><Link to='https://vimeo.com/xmediavideos'>Vimeo</Link></li>
                <li><Link to='https://www.instagram.com/xmedia/'>Instagram</Link></li>
                <li><Link to='https://www.tiktok.com/@x_media'>TikTok</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`contact ${ contact && 'active' }`}>
          <div className='contact__inner'>
            <button onClick={this._toggleContact} className='contact__close'>
              Close
              <span className='lines'></span>
            </button>
            <h3>Let’s make magic happen.</h3>
            <Form />
          </div>
        </div>
      </>
    )
  }
}

export default Header
